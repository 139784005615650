@font-face {
    font-family: 'Figtree';
    src: local('Figtree'), url(./style/Figtree/Figtree-VariableFont_wght.ttf) format('truetype');
}
body {
    margin: 0px !important
}

* {
    /*font-family: 'SF Pro Text' !important;*/
    /*font-family: -apple-system, BlinkMacSystemFont, sans-serif;*/
    font-family: 'Figtree', sans-serif;
    font-style: normal;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

.nimbly-login-wrapper {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100%;
}

.nimbly-login-wrapper-left-container {
    width: 50%;
    height: 100%;
    float: left;
}

.nimbly-login-login-window {
    position: absolute;
    width: 486px;
    height: 550px;
    background: #FFFFFF;
    box-shadow: -10px 10px 10px rgba(191, 191, 191, 0.3);
    border-radius: 10px;
    top: 200px;
    left: 128px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 25%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.nimbly-login-logo {
    position: absolute;
    width: 298px;
    height: 100px;
    top: 98px;
    left: 90px;
}

.nimbly-login-logo-hint-text {
    position: absolute;
    width: 146px;
    height: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
    top: 164px;
    left: 241px;
}

.nimbly-login-loginwindow-text {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}

.nimbly-login-button-container {
    text-align: center;
    margin-top: 300px;
    font-weight: 600;
    font-size: 16px;
    line-height: 50px;
}

.nimbly-loginButton {
    background-color: #1260CE;
    width: 35vh;
    height: 40px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer
}

.nimbly-logoutButton {
    background-color: #1260CE;
    width: 8vh;
    height: 30px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    font-size: 10px

}

.nimbly-login-loginwindow-form {
    margin: 349px 72px 20px;
}

.nimbly-login-help-link {
    color: #1260CE;
}

.nimbly-loading-icon {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.nimbly-loading-icon-transfer {
    margin: 0;
    position: absolute;
    top: 70%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.nimbly-login-Signinbutton {
    background: #CF1322;
    width: 40vh;
}

.nimbly-login-forgetpwdlink {
    position: absolute;
    /* top: 0px; */
    left: 83px;
    width: 200px;
    color: #CF1322;
}

.nimbly-login-input {
    width: 40vh;
}

.nimbly-login-button-wrapper .ant-row .ant-col {
    margin: 0 !important
}

.nimbly-header-topnav {
    background: white !important;
    box-shadow: 5px 2px 5px 0px #c8c8c8 !important;
    margin-bottom: 4px;
    height: 50px !important;
    padding-inline: 25px !important;
}

.nimbly-header-user-profile {
    text-align: right !important;
    margin-top: 3px;
}

.nimbly-profile-picture {
    height: 23%;
    border-radius: 50px;
}


.nimbly-header-profile-name {
    line-height: 1px !important;
    font-weight: 700 !important
}

.nimbly-header-profile-email {
    line-height: 1px !important;
}

.nimbly-dashboard-TransferTable {
    max-width: auto !important;
    min-width: 330px !important;
}

.nimbly-dashboard-chart {
    padding: 0px !important
}

.nimbly-dashoboard-transfer-card {
    margin: 10px 0px 10px;
    height: 100%;
    border: 1px solid #c9c9c9;
    border-radius: 8px 8px 8px 8px;
}

.nimbly-dashboard-transfer-header {
    margin: 5px !important
}

.nimbly-dashboard-transfer-selection {
    background: blue;
    border-radius: 8px 8px 0px 0px;
    padding: 10px 20px 5px 20px;
    background: #D9E8FC
}

.nimbly-dashboard-transfer-table {
    /*margin-top: 30px;*/
    overflow: auto;
    height: 16vh;
}

.nimbly-dashboard-selection-tag {
    background: white;
}

.nimbly-querywizard-title {
    font-weight: 700;
}

.nimbly-report-location-tableview {
    width: 100%;
}

    .nimbly-report-location-tableview .ant-table {
        line-height: 15px !important;
        border: 1px solid lightgrey;
        margin: 0px !important;
    }

.nimbly-report-location {
    width: 100%;
    margin-top: 30px
}

    .nimbly-report-location .ant-table {
        line-height: 15px !important;
        border: 1px solid lightgrey;
    }

.nimbly-report-pagination-wrapper {
    text-align: center;
    width: 100%;
    height: 25px;
    display: inline-block;
    align-self: flex-end;
}

.gm-ui-hover-effect {
    display: none !important;
}

.nimbly-map-legendbox {
    z-index: 999;
    position: absolute;
    bottom: 2px !important;
    color: black;
    margin: 10px !important;
    text-align: left !important;
    box-shadow : none !important
}

    .nimbly-map-legendbox .ant-card-body{
        padding: 5px !important;
    }



    .nimbly-chart-container {
        width: 100%;
    }

.nimbly-chart-container-hidden {
    display: none;
}

.ant-layout-content {
    padding: 12px !important;
}

.nimbly-transfer-table-container {
    padding: 10px 0px 10px 10px;
}

.nimbly-transfer-table-container-hidden {
    display: none;
}

.nimbly-trasfer-card-wapper-div {
    height: 47% !important;
    width: 100% !important
}

.nimbly-chart-card .ant-card-body {
    height: 32vh;
    width: 100% !important;
    border: 1px solid #c9c9c9;
    border-radius: 8px 8px 8px 8px;
}

.nimbly-chart-card {
    height: 100%;
    width: 100%;
}

.nimbly-topnav-logo {
    margin: 15px 10px 12px 10px;
}

.nimbly-company-logo {
    margin: 35px 24px 30px 20px;
    height: 51px;
}

.ant-menu-item-selected {
    padding-left: 24px;
    border-right: 3px solid #1260CE;
    border-radius: 0px !important;
}

.nimbly-sider-icon{
    margin-right: 20px;
    font-size: 18px !important
}
.nimbly-title-text{
    font-weight: 700;
    font-size: 16px;
}
.nimbly-title-text-large {
    font-weight: 700;
    font-size: 16px;
}
.nimbly-title-text-small {
    font-weight: 700;
    font-size: 14px;
}
.nimbly-title-text-map {
    font-weight: 700;
    font-size: 16px;
    margin: 20px 0px 10px 0px
}

.nimbly-transfer-selector {
    margin-left: 139px;
    margin-bottom: 20px;
}

.nimbly-transfer-row {
    padding: 5px 0px 0px 22px !important;
}



.nimbly-image-card {
    margin: 0 auto
}


.nimbly-image-card .ant-card-body {
    padding: 8px !important;
}

.nimbly-image-card-pdf .ant-card-body {
    /*margin: 0 auto*/
    height: 100%;
    padding: 8px !important;
}
.nimbly-report-goback-button{
    margin-bottom: 10px;
}

.nimbly-image-container {
    width: 80%;
    margin: 0px auto;
    width:100%
}

.nimbly-table-view-contrainer {
    width: 130vh;
    margin: 0px auto;
}

.nimbly-table-view-contrainer-pdf {
    width: 180vh;
    margin: 0px auto;
}

.nimbly-table-view-table .ant-table {
    width: 100%;
    /*line-height: 1px !important*/
}

.nimbly-table-view-table-pdf {
    width: 100%;
}
    .nimbly-table-view-table-pdf .ant-table {
        line-height: 15px !important;
    }
.nimbly-report-information .ant-table {
    line-height: 18px !important;
    border: 1px solid lightgrey;
    margin: 0px 5px 0px 1px !important;
}

.nimbly-report-information-pdf {
    width:33%;
}

    .nimbly-report-information-pdf .ant-table {
        line-height: 5px !important;
        margin: 5px;
    }

.nimbly-query-form {
    margin: 0px auto;
}

.nimbly-querywizard-container {
    width: 60%;
    margin: 0px auto;
    /*height: 100%;*/
    border: 1px solid #c9c9c9;
    border-radius: 8px 8px 8px 8px;
}

.nimbly-chart-checkbox {
    margin: auto;
    text-align: center;
}

.nimbly-chart-year-selector-container{
    text-align:right
}

.nimbly-chart-year-selector .ant-select-selector {
    border-radius: 0px !important;
}

.nimbly-map-info {
    font-size: 10px;
    color: #3361cd;
    font-weight: 700;
    text-align: center;
    width: 100%;
    padding: 5px;
}

.nimbly-map-info-text{
    margin:auto;
}

.nimbly-query-form .ant-form-item{
    margin-bottom: 2px !important
}

.nimbly-noimage-message {
    font-size: 20px;
    width: 150px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center
}

.nimbly-message-container {
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.nimbly-message-container .ant-card-body {
    width: 100%;
    height: 100%
}

.nimbly-dashoboard-transfer-card .ant-card-body {
    padding: 0px !important;
/*    border: 1px solid #c9c9c9;
    border-radius: 8px 8px 8px 8px;*/
}

.ant-drawer {
    height: 72% !important;
    width: 235px !important;
}

.ant-drawer-content-wrapper {
    height: 100% !important;
    width: 100% !important;
}

.nimbly-draw-show {
    position: relative;
    height: 192px;
    padding: 0px;
    overflow: hidden;
    text-align: center;
    width:341px
}

.nimbly-draw-hidden {
    position: relative;
    height: 192px;
    padding: 0px;
    overflow: hidden;
    text-align: center;
    width: 341px
}

.nimbly-drawer-container{
    height:100%;
    width: 200px;
}

.nimbly-drawer-show-button {
    left: 45px;
    top: 33%;
    background: white;
    height: 43px;
    width: 20px;
    border-radius: 0px 10px 10px 0px;
    color: black;
    padding: 0px;
}

.nimbly-drawer-close-button {
    left: -200px;
    top: 33%;
    background: white;
    height: 43px;
    width: 20px;
    border-radius: 0px 10px 10px 0px;
    color: black;
    padding: 0px;
}

.nimbly-map-expand-button {
    right: 0;
    top: 0;
    background: white;
    height: 35px;
    width: 35px;
    /* border-radius: 0px 10px 10px 0px; */
    color: black;
    padding: 0px;
    position: absolute;
    /* float: right; */
    margin: 10px;
    font-size: 21px;
}

.nimbly-map-expand-full-button {
    right: 0;
    top: 0;
    background: white;
    height: 35px;
    width: 35px;
    /* border-radius: 0px 10px 10px 0px; */
    color: black;
    padding: 0px;
    position: absolute;
    /* float: right; */
    margin: 10px;
    font-size: 21px;
}

.nimbly-drawer-row {
    position: relative;
    height: 192px;
    padding: 0px;
    overflow: hidden;
    text-align: center;
    width: 341px;
    top: 50%
}

.nimbly-drawer-row-expanded {
    position: relative;
    height: 192px;
    padding: 0px;
    overflow: hidden;
    text-align: center;
    width: 341px;
    top: 68%
}

.ant-drawer-body{
    padding: 2px !important
}
.ant-drawer-content {
    border-radius: 20px;
    margin-left: 10px;
}

.nimbly-report-view {
    width: 100%;
    margin: auto
}

.nimbly-report-mapview {
    height: 55%;
    margin: auto
}

.nimbly-transfer-dropdown{
    text-align: right;
}

.nimbly-transfer-datepicker{
    margin-top: 3px;
    width: 100%;
}

.nimbly-map-infowindow {
    max-width: 93vh !important
}

.gm-style-iw {
    max-width: 93vh !important
}

.nimbly-querywizard-input{
    width: 350px;
}

.nimbly-querywizard-bottonrow {
    /*bottom: 5px;*/
    /*position: absolute;*/
    width: 100%;
    margin-top: 10px;
}

.nimbly-querywizard-container .ant-card-body {
    padding: 10px !important;
}

.nimbly-wizard-warning .ant-modal-content .ant-modal-footer{
    text-align: center;
}

.nimbly-wizard-warning-button{
    width: 180px;
}

.recharts-legend-wrapper {
    position: absolute !important;
    width: 811px !important;
    height: 25px !important;
    left: 193px !important;
    top: -27px !important;
}

.nimbly-map-spin{
    position:absolute;
    top:50%;
    left: 50%;
    z-index:999;
}

.nimbly-map-loading {
    background-color: black;
    opacity: 0.5;
    pointer-events: none;
    border-radius: 10px;

}

/*checkboxes*/
.ant-checkbox-inner {
    border: 2px solid !important;
}

.nimbly-chart-yearly-checkbox .ant-checkbox-inner {
    border-color: dodgerblue !important;
}


.nimbly-chart-monthly-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FADB14 !important;
    border-color: #FADB14 !important;
}

.nimbly-chart-monthly-checkbox:hover .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
    background-color: #FADB14 !important;
    border-color: #FADB14 !important;
}

.nimbly-chart-monthly-checkbox .ant-checkbox-inner {
    border-color: #FADB14 !important;
}


/*checkboxes nimbly-map-checkbox-SimpleTransfers*/
.nimbly-map-checkbox-SimpleTransfers {
    /*margin-left: 8px*/
}
    .nimbly-map-checkbox-SimpleTransfers .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #85A5FF !important;
        border-color: #85A5FF !important;
    }

    .nimbly-map-checkbox-SimpleTransfers:hover .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
        background-color: #85A5FF !important;
        border-color: #85A5FF !important;
    }

    .nimbly-map-checkbox-SimpleTransfers .ant-checkbox-inner {
        border-color: #85A5FF !important;
    }

    /*checkboxes nimbly-map-checkbox-ComplexTransfers*/
.nimbly-map-checkbox-ComplexTransfers .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #9254DE !important;
    border-color: #9254DE !important;
}

.nimbly-map-checkbox-ComplexTransfers:hover .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
    background-color: #9254DE !important;
    border-color: #9254DE !important;
}

.nimbly-map-checkbox-ComplexTransfers .ant-checkbox-inner {
    border-color: #9254DE !important;
}
/*checkboxes nimbly-map-checkbox-IdlePoles*/
.nimbly-map-checkbox-IdlePoles .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FADB14 !important;
    border-color: #FADB14 !important;
}

.nimbly-map-checkbox-IdlePoles:hover .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
    background-color: #FADB14 !important;
    border-color: #FADB14 !important;
}

.nimbly-map-checkbox-IdlePoles .ant-checkbox-inner {
    border-color: #FADB14 !important;
}
/*checkboxes nimbly-map-checkbox-LocationsComplete*/
.nimbly-map-checkbox-LocationsComplete .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #389E0D !important;
    border-color: #389E0D !important;
}

.nimbly-map-checkbox-LocationsComplete:hover .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
    background-color: #389E0D !important;
    border-color: #389E0D !important;
}

.nimbly-map-checkbox-LocationsComplete .ant-checkbox-inner {
    border-color: #389E0D !important;
}
/*checkboxes nimbly-map-checkbox-LocationsNotAudited*/
.nimbly-map-checkbox-LocationsNotAudited .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #14FAC3 !important;
    border-color: #14FAC3 !important;
}

.nimbly-map-checkbox-LocationsNotAudited:hover .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
    background-color: #14FAC3 !important;
    border-color: #14FAC3 !important;
}

.nimbly-map-checkbox-LocationsNotAudited .ant-checkbox-inner {
    border-color: #14FAC3 !important;
}
/*checkboxes nimbly-map-checkbox-LocationsWaiting*/
.nimbly-map-checkbox-LocationsWaiting .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FC2DCF !important;
    border-color: #FC2DCF !important;
}

.nimbly-map-checkbox-LocationsWaiting:hover .ant-checkbox-checked:hover .ant-checkbox-inner:hover {
    background-color: #FC2DCF !important;
    border-color: #FC2DCF !important;
}

.nimbly-map-checkbox-LocationsWaiting .ant-checkbox-inner {
    border-color: #FC2DCF !important;
}

.nimbly-home-content {
    padding: 22px;
    margin: 0;
    min-height: 280px;
    background: white;
    border-radius: 8px;
    height: 100%;
    overflow: auto;
}

.nimbly-home-content .ant-space{
    height: 90%;
}

.nimbly-report-title{
    font-size: 18px;
    font-weight: 700;
}

.nimbly-noaccess-container {
    width: 100vh;
    height: 80vh;
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.nimbly-noaccess-message-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-shadow: 3px 3px 3px #ceddff;
    border-radius: 25px;
}

.nimbly-noaccess-image {
    width: 40vh;
    height: 40vh;
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}


.nimbly-noaccess-message-text {
    color: dodgerblue;
    font-weight: 600;
}

.nimbly-noaccess-message-text-content{
    font-weight:600;
}

.nimbly-topnav-switchregin{
    top: -20px;
    left: 30px;
}

.nimbly-landingpage-message-container {
    position: absolute;
    bottom: 0;
    width: 80%;
    box-shadow: 3px 3px 3px #ceddff;
    border-radius: 25px;
}

.nimbly-landingpage-button {
    background-color: #40A9FF;
    color: white;
    border-radius: 0px;
    margin-top: 20px;
}

.nimbly-landingpage-button:hover {
    background-color: #40A9FF;
    color: white !important;
    border-radius: 0px;
    margin-top: 20px;
}

.nimbly-landingpage-image {
    width: 32vh;
    height: 32vh;
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.nimbly-landingpage-container {
    width: 100vh;
    height: 80vh;
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nimbly-loading-container{
    min-height: 200px;
}

.nimbly-report-columnName{
    cursor: auto;
    color: black;
}

    .nimbly-report-columnName:hover {
        cursor: auto;
        color: #1677ff;
        color: black;
    }

.ant-radio-button-wrapper-checked {
    border: 0px !important;
    background-color: white !important;
    box-shadow: 0.5px 1px #888888;
}

.nimbly-wizard-radio {
    background-color: #BFBFBF !important;
    font-weight: 400;
    padding: 2px;
    width: 244px;
    border-radius: 3px !important;
}

.nimbly-wizard-radio-button {
    background-color: #BFBFBF;
    color: white;
    height: 28px;
    border: 0px !important;
    border-radius: 3px !important;
    width: 120px;
    text-align: center;
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-radio-button-wrapper-checked:not(:where(.css-dev-only-do-not-override-1i9hnpv).ant-radio-button-wrapper-disabled)::before {
    background-color: #BFBFBF;
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-table-wrapper .ant-table-row-expand-icon {
    border-radius: 0px;
    border: 1px solid black;
}

.nimbly-queryreport-polenum{
    font-weight: 700;
}

/*.nimbly-report-table{
    height:100%;
}

.ant-table-body {
    min-height: 500px;
}*/

.nimbly-report-table .ant-spin-nested-loading .ant-spin-container {
    /*height: 90%;*/
}
.nimbly-report-table .ant-table-container {
    box-shadow: none !important;
}
.ant-table-body {
    min-height: 570px;
}

/*:where(.css-dev-only-do-not-override-1i9hnpv).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin-block: auto !important;
    margin-inline: auto !important;
}*/
.nimbly-user-management {
    margin-top: 0px !important;
}

.nimbly-user-management .ant-table-thead .ant-table-cell {
    background-color: #D9E8FC;
}

.nimbly-icon-green{
    background-color: green;
    color:white;
    margin-right:5px;
}

.nimbly-icon-red {
    background-color: red;
    color: white;
    margin-right: 5px;
}

.nimbly-icon-gray {
    background-color: white;
    color: gray;
    margin-right: 5px;
}

.nimbly-textcolor-gray {
    color: gray;
}

.nimbly-textcolor-lightgray {
    color: lightgrey;
}

.nimbly-icon-lightgray-disable {
    background-color: lightgrey;
    color: white;
    margin-right: 5px;
}
.nimbly-icon-gray-disable {
    background-color: white;
    color: lightgrey;
    margin-right: 5px;
}

.nimbly-user-counts{
    margin-top:30px;
}

.nimbly-add-new-user {
    float: right;
    margin-bottom: 20px;
    background-color: dodgerblue;
    border-radius: 0px;
    color: white;
}

.nimbly-add-new-user-window {
    margin-bottom: 20px;
    background-color: dodgerblue;
    border-radius: 0px;
    color: white;
}

    .nimbly-add-new-user-window:hover {
        margin-bottom: 20px;
        background-color: dodgerblue;
        border-radius: 0px;
        color: white !important;
    }

.nimbly-add-new-user:hover {
    float: right;
    margin-bottom: 20px;
    background-color: dodgerblue;
    border-radius: 0px;
    color: white !important;
}

.nimbly-user-edit-window{
    padding: 5px;
}

.nimbly-user-edit-window-icon{
    color:lightgreen;
}

.nimbly-registration-link {
    cursor: pointer
}

.nimbly-registration-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.nimbly-user-window-fontweighted{
    font-weight: 600;
}

.nimbly-chart-tooltip{
    color:black !important;
}

